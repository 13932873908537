h1, h2, h3{
  font-family: 'Roboto', sans-serif!important;
  font-weight: bold!important;
}

h4, h5, h6{
  font-family: 'Roboto', sans-serif!important;
  font-weight: 500!important;
}

p{
  font-family: 'Roboto', sans-serif!important;
}

.main-content-container, body,.main-content,.container-fluid, #root{
  background-color: #ffffff!important;
}

.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}

.nav-ffort .nav-item .nav-link{
  color: #e94e1b!important;
}
.nav-ffort .nav-item .nav-link .material-icons{
  color: #e94e1b!important;
}

.min-twee{
  background-image: url('/assets/images/background-dummy.jpg');
}

.settingsBlocks{
  background-color: #e94e1b;
  padding: 10% 5% 10% 5%;
  border-radius: 20px;
  margin-bottom: 10%;
}
.active-block{
  opacity: 0.5;
  background-color: #e94e1b;
  padding: 10% 5% 10% 5%;
  border-radius: 20px;
}

.active-block a{
  color: white!important;
  display: flex;
  justify-content: center;

}

.settingsBlocks a{
  color: white!important;
  text-align: center;
  display: flex;
  justify-content: center;

}

.settingsBlocks i{
  color: white;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.settingsBlocksCat i{
  color: white;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.settingsBlocksCat{
  background-color: #e94e1b;
  padding: 20% 5%;
  border-radius: 20px;
  width: 90%;
  height: 90%;
  overflow-wrap: break-word;
}

.logout-button{
  position: absolute;
    bottom: 0;
    left: 0;
  font-weight: bold;
  padding: .9375rem 1.5625rem;
  background-color: #e94e1b;
  width: 100%;
  color: white;
}

.logout-button:hover{
  background-color: #FF8136;
}
.titles-row{
  margin-bottom: 2%;
}




.image-steps{
  width: 40%;
  margin-left: 30%;
  margin-top: 5%;
}

.logo-ffort{
  width: 30%;
  margin-top: 10%;
  margin-bottom: 5%;  
  margin-left: 35%;

}

.image-steps-title{
  text-align: center;
  margin-top: 13%;
  margin-bottom: 13%;
  color: white;
  font-weight: bold;

}

.input-field-sets{
  width: 100%;
  border-bottom-style: solid;
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 2%;
  border-bottom-color: grey;
  border-bottom-width: 1px;
}

.bord-settings textarea{
  width: 50%;
    height: 100px;
    border-bottom-width: 2px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
}

.comment-section textarea{
  width: 50%;
  height: 50px;
  border-bottom-width: 2px;
  border-left-width: 0px;
  border-top-width: 0px;
  border-right-width: 0px;
}

.image-steps-select{
color: white;
}

.bord-settings{
margin-top: 2%;
}

.titles-setup{
  font-weight: bold;

}

.button-setup{
  background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #e94e1b!important;
    
}

.button-setup:hover, .link-setup-2:hover{
  background-color: #fa784d!important;
}

.button-setup-company{
  background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding: 2% 4%!important;

    border-color: #e94e1b!important;
    color: white;
    border-width: 0px;
    font-size: 15pt;
    border-color: #e94e1b!important;
    color: white;
    border-width: 0px;
    font-size: .75rem;
    font-weight: 500!important;
}

.button-reset{
  background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #e94e1b!important;
    margin-bottom: 5%;
}

.participants{
  margin-top: 5%;
  margin-bottom: -5%!important;
}

.button-cat{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  font-weight: bold!important;
  border-color: #e94e1b!important;
  width: 100%;
  margin-top: 15%;
  padding: 4% 8%!important;
}

.button-add{
  background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    font-weight: bold!important;
    border-color: #e94e1b!important;
    width: 100%;
    margin-top: 15%;
}

.button-add-category{
  background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding-left: 8%!important;
    padding-right: 8%!important;
    font-weight: bold!important;
    border-color: #e94e1b!important;
    width: 30%;
    margin-top: 3%;
}

.link-setup{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 1%;
  font-weight: 700!important;
  border-color: #e94e1b!important;
  color: white!important;
  font-size: 9pt;
}

.link-setup-comment{
  width: 18%;
    padding: 1% 3%!important;
    background-color: white!important;
    color: black!important;
    border-width: 2px!important;
    border-style: solid;
    border-color: #e94e1b!important;
    font-size: 9pt;
    border-radius: 50px!important;
    font-weight: 700!important;
}
.link-setup-comment:hover{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  padding: 1% 3%!important;
  font-weight: 700!important;
  border-color: #e94e1b!important;
  color: white!important;
}

.link-setup-2{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 2%;
  font-weight: 700!important;
  border-color: #e94e1b!important;
  color: white!important;
  font-size: 9pt;
}

.link-setup-afronden{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 2.5%;
  font-weight: 700!important;
  border-color: #e94e1b!important;
  color: white!important;
  font-size: 9pt;
  width: 75%;
  text-align:  center;
}

.link-setup-categorie{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;
  padding: 1%;
  font-weight: 700!important;
  border-color: #e94e1b!important;
  color: white!important;
  font-size: 9pt;
  width: 75%;
  text-align:  center;
  margin-top: 5%!important;
}

.button-adding{
  margin-top: 2%;
}

.button-sorting{
  background-color: #e94e1b!important;
  border-radius: 50px!important;
  padding-left: 10%!important;
  padding-right: 10%!important;
  font-weight: bold!important;
  padding-top: 3%!important;
  padding-bottom: 3%!important;
  width: 100%;
 
}

.bord-settings input{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid;
  border-bottom-width: 1px;
  padding: 2%;
  width: 50%;
}

.bord-settings li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}
.archive-list{
  margin-bottom: 5%;
}

.error-message{
  background-color: rgba(255, 0, 0, 0.8);
  padding: 3%;
  padding-bottom: 0.2%;
  border-radius: 20px;
  color: white;
  margin-top: 3%;
}

.error-message-image{
  background-color: rgba(255, 0, 0, 0.8);
  padding: 1%;
  border-radius: 20px;
  color: white;
  width: 50%;
}

.error-message-image p{
  margin-bottom: 0px!important;
}


.archive-list h6{
  padding-bottom: 1%;
  margin-top: 5%;

}

.archive-list li{
  list-style: none;
  border-bottom-style: solid;
  width: 100%;
  padding-bottom: 1%;
  border-bottom-width: 1px;
  margin-top: 1%;
}

.bottom-col{
  margin-bottom: 10%;
}

.modal-background{
  height: 100vh;
  background-size: cover;
}

.start-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;

  margin-top: 10%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.end-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;

  margin-top: 4%;
  padding-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-block-left{
  background-color: white;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: none;
  border-width: 1px;
  margin-top: 8%;
  padding-bottom: 3%;
  padding-top: 2.5%;
}

.login-block-left h3{
  text-align: center;
  font-weight: bolder;
  font-family: 'Montserrat' ;
}

.login-block-left h4{
  text-align: center;
  font-family: 'Montserrat' ;
}

.login-block-left p{
  text-align: center;
  font-family: 'Montserrat' ;
  font-weight: 300;
}

.login-block-left input{
  width: 70%;
  margin-left: 15%;
  padding:1%;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #707070;
  padding-left: 3%;
}

.login-block-left button{
  margin-left: 13%;
  width: 74%;
  padding: 2%;
}

.login-block-right{
  background-color: white;
  background-image: url('./images/login-image.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-width: 1px;
  margin-top: 8%;
  padding-bottom: 5%;
  display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.question-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-bottom: 3%;
 
}

.sorting-row{
  padding-left: 14%;
}
.sorting-block{
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin-top: 5%;
  padding-top: 1%;
  padding-bottom: 3%;
 width: 90%;
 margin-right: 0.15%;

}

.sorting-block h5{
  margin-bottom: 5%;
  font-weight: bold;
  font-size: 21pt;
  border-bottom: solid;
  padding-bottom: 3%;
  
}

.sorting-block li{
  list-style: none;
  border-bottom-style: solid;
  width: 80%;
  padding-bottom: 2%;
  border-bottom-width: 1px;
  margin-bottom: 2%;
}

.block-middle{
  padding-top: 5%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling{
  padding-top: 2%!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling h5{
  font-weight: bolder;
  font-style: italic;
}

.block-middle-stelling-qsort{
  padding-top: 1%!important;
  padding-bottom: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-middle-stelling-qsort h5{
  font-weight: bolder;
  font-style: italic;
}
.block-middle h5{
  font-weight: bolder;
}

.block-middle h6{
  text-align: center;
}
.block-middle-text{
  margin-top: 2%;
  text-align: center;
}

.block-middle-text p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
}

.block-middle-text-question{
  margin-top: 2%;
  text-align: center;
}
.block-middle-text-question p{
  margin-bottom: 3%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 18pt;
}

.block-middle-text-left{
padding-top: 5%;
}

.agrees-table{
  border-color: #e94e1b;
  border-width: 3px;
}

.agrees-table h5{
  border-color: #e94e1b;
}

.naturals-table{
  border-color: #e94e1b;
  border-width: 3px;
  background-color: #F2B28C;
  color: white;
}

.naturals-table h5{
  border-color: #e94e1b;
  color: white;
}
.disagrees-table{
  border-color: #e94e1b;
  border-width: 3px;
  background-color: #e94e1b;
  color: white;
}

.marginbottom{
  margin-bottom: 5%;
}

.disagrees-table h5{
  border-color: white;
  color: white;
}


.image-check{
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category{
  color: #e94e1b;
  font-weight: bold;
  margin-left: 2%;
  margin-right: 2%;
}

.date-list{
  margin-left: 2%;
  margin-right: 2%;
}

.see-list{
  float: right;
  margin-right: 2%;
}

.see-list-delete i{
  float: right;
  margin-right: 1%;
  color:#FF0000;
  margin-top: 0.5%;
}

.see-list-edit i{
  float: right;
  margin-right: 1%;
  margin-top: 0.5%;
}

.question-list{
  margin-right: 2%;
}

.select-box-bord{
  width: 50%;
  background-color: white;
  border-radius: 0px!important;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 2%;
}

.nav-ffort .nav-item .nav-link.active, .nav-ffort .nav-item .nav-link:hover{
box-shadow: inset 0.1875rem 0 0 #e94e1b!important;
}

.custom-Class div{
  width: 100%!important;
}

.position-title{
  border-bottom-style: solid;
  border-bottom-width: 2px;

  margin-bottom: 5%;
}
.position-title h3{
  text-align: center;
  background-color: white;
  margin-bottom: 4%!important;
}

.position-negative div{
  background-color: #e94e1b;
}


  .one{
    background-image: url('./images/background-1-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 18vh;
  }
  
  .two{
    background-image: url('./images/background-2-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 27vh;
  }
  
  .three{
    background-image: url('./images/background-3-groot.jpg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center; /* Center the image */
    height: 35vh;
  }

  .position-positive div{
    background-color: white!important;
    padding: 0px!important;
  
  }
  
  .position-positive div div{
    padding: 10px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
    padding: 17px!important;
  }
  
  .position-natural div{
    background-color: #F2B28C;
    padding: 0px!important;
  }
  
  .position-natural div div{
    background-color: #F2B28C!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }
  
  .position-negative div{
    background-color: #ED6A1F;
    padding: 0px!important;
  }
  
  .position-negative div div{
    background-color: #ED6A1F!important;
    color: white!important;
    padding: 17px!important;
    border-color: #ED6A1F!important;
    border-style: solid;
    border-width: 2px;
  }
@media only screen and (max-width: 1280px) {
.one{
  background-image: url('./images/background-1.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 18vh;
}

.two{
  background-image: url('./images/background-2.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 27vh;
}

.three{
  background-image: url('./images/background-3.jpg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center; /* Center the image */
  height: 35vh;
}

.position-positive div{
  background-color: white!important;
  padding: 0px!important;

}

.position-positive div div{
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;

}

.position-natural div{
  background-color: #F2B28C;
  padding: 0px!important;
}

.position-natural div div{
  background-color: #F2B28C!important;
  color: white!important;
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;
}

.position-negative div{
  background-color: #ED6A1F;
  padding: 0px!important;
}

.position-negative div div{
  background-color: #ED6A1F!important;
  color: white!important;
  padding: 10px!important;
  border-color: #ED6A1F!important;
  border-style: solid;
  border-width: 2px;
}

.login-block-left{
  background-color: white;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: none;
  border-width: 1px;

  margin-top: 5%;
  padding-bottom: 3%;
  padding-top: 2.5%;
}
.login-block-right{
  background-color: white;
  background-image: url('./images/login-image.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center; /* Center the image */
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: none;
  border-width: 1px;

  margin-top: 5%;
  padding-bottom: 5%;
  display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}
}



.class-group-natural{
  background-color: #F2B28C;
  width: 60%;
  margin-right: 1%;
  margin-left: 4%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #e94e1b;
  border-width: 2px;
  border-style: solid;
}

.class-group-natural h4,.class-group-disagree h4{
  color: white!important;
  font-weight: bold;
  margin-top: -2%;
}

.class-group-agree h4{
  font-weight: bold;
  margin-top: -2%;
}

.class-group-natural div{
  background-color: #F2B28C!important;
  padding: 0px!important;
}

.class-group-natural div{
  background-color: none!important;
  width: 100%!important;
}

.class-group-natural div div{
  background-color: #F2B28C!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  border-bottom-color: white!important;
  color: white!important;
  max-width: 450px;
}

.class-group-agree{
  background-color: white;
  width: 60%;
  margin-right: 0%;
  margin-left: 3%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #F2B28C;
  border-width: 2px;
  border-style: solid;
}

.class-group-agree div{
  background-color: white!important;
  width: 100%!important;
  padding: 0px!important;
}

.class-group-agree div div{
  background-color: white!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  max-width: 450px;
}

.class-group-disagree{
  background-color: #e94e1b;
  width: 60%;
  margin-right: 0%;
  margin-left: 10%;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-color: #e94e1b;
  border-width: 2px;
  border-style: solid;
}

.class-group-disagree div{
  background-color: #e94e1b!important;
  width: 100%!important;
  padding: 0px!important;
}

.class-group-disagree div div{
  background-color: #e94e1b!important;
  padding: 3px!important;
  border-bottom-style: solid!important;
  border-bottom-width: 1px!important;
  color: white!important;
  max-width: 450px;
}


.background-white{
  background-color: white!important;
}

.Drops{
  margin-top: 5%;
  margin-left: 9.5%!important;
  width: 100%;
  margin-right: 8%;
}

.add-participant{
  border-radius: 50px!important;
  padding-left: 8%!important;
  padding-right: 8%!important;

  font-weight: 700!important;
  border-color: black!important;
  color: black!important;
  font-size: 9pt;

}

.qsortdone{
  border-style: solid;
  border-color: black;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1%;
  border-radius: 50px;
  margin-top: 12%;
}

.qsortdone button{
  width: 100%!important;
  padding: 3%!important;
  margin-left: 2%!important;
  margin-right: 2%!important;
}

.button-sortdone{
  background-color: #e94e1b!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;

    font-weight: bold!important;
    border-color: #e94e1b!important;
    
}

.button-restart{
  background-color: white!important;
    border-radius: 50px!important;
    padding: 2% 8%!important;
    color: #757575!important;
    font-weight: bold!important;
    border-color: #ED6A1F!important;
    
}

.logo-client{
  float: right;
  margin-top: 9%;
  margin-bottom: -9%;
  margin-right: 3%;
  width: 50%;
}

.question-instruction p{
    float: right;
    margin-top: 4%;
    background-color: #e94e1b;
    padding: 2% 4%;
    border-radius: 25px;
    margin-right: 3%;
    color: white;
    margin-bottom: -4%;
}

.question-instruction p:hover{
  float: right;
  margin-top: 4%;
  background-color: #a34c19;
  padding: 2% 4%;
  border-radius: 25px;
  color: white;
}

.overlay{
  position: fixed!important;
  width: 100%!important;
  height: 100%!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  cursor: pointer;
  padding: 5% 0% 20% 20%!important;
}

.add-appointment-block{
  background-color: white;
  padding: 5% 5%!important;
}

.instruction-title{
  font-weight: bold!important;
    color: #e94e1b;
    font-size: 18pt;
}

.add-appointment-block p{
  margin-bottom: 0!important;
}

.close-instruction{
  position: absolute;
    float: right;
    right: 20px;
    top: 15px;
    color: red;
}

.close-instruction:hover{
  opacity: 0.5;
}

.check-positive{
  color:#228B22;
}

.blocks{
  padding-left: 0px!important;
  margin-top: 2%;
}

